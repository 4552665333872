@font-face {
  font-family: Myriad Pro Regular;
  src: url('/assets/myriadpro-regular.woff');
}

@font-face {
  font-family: Gotham Medium;
  src: url('/assets/gotham-medium.woff');
}

body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  //background: white;
  color: #434343;
  font-family: 'Myriad Pro Regular', 'Times', 'Times New Roman', 'Hiragino Mincho Pro W3', 'Georgia', 'serif';
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@import '~@angular/material/theming';

@include mat-core();
$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent: mat-palette($mat-light-green, A200, A100, A400);
$candy-app-warn: mat-palette($mat-red);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include angular-material-theme($candy-app-theme);

//
.seaman {
  background-image: url("/assets/img/top/index_seaman_0w.jpg");
}

.landbased {
  background-image: url("/assets/img/top/index_landbased_0w.jpg");
}

.bb-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  margin-bottom: -100vh;
  position: fixed;
  z-index: -1;
}
